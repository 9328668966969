.modalFooter {
  display: flex;
  flex: 0 0 var(--modalFooterHeight);
  align-items: center;
  justify-content: flex-end;
  padding: 0 15px;
  height: var(--modalFooterHeight);
  border-top: 1px solid var(--borderColor);
  text-align: right;
  background: var(--surface6Color);
  z-index: 1;
  border-radius: 0 0 var(--modalCornerRadius) var(--modalCornerRadius);
}

@media (max-height: 760px) {
  .modalFooter {
    flex: 0 0 var(--modalContractedFooterHeight);
    height: var(--modalContractedFooterHeight);
  }
}

.modalSpace {
  flex: 1 1 auto;
}

.modalFooter button {
  margin-left: var(--largeMargin);
}

.modalFooter button:first-child {
  margin-left: 0;
}
