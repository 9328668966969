.treeOption {
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  gap: var(--smallMargin);
  font-size: var(--labelFontSize) !important;
}

.arrowContainer {
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.treeOption > p,
.arrowContainer {
  padding: var(--smallMargin) 0;
}

.icon {
}

.icon.open {
  transform: rotate(90deg);
}

.treeOptionHidden {
  display: none;
}

.expand {
  flex-grow: 1;
}
.treeOption:nth-child(even of :not(.treeOptionHidden)):hover,
.treeOption:nth-child(odd of :not(.treeOptionHidden)):hover {
  background-color: var(--surface5Color);
}
