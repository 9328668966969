.spacedItem {
  margin: 10px;
}

.wrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.wrapper select {
  margin-right: 20px;
}

.md5Text {
  font-size: 0.8em;
  font-family:
    'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, 'Courier New',
    monospace;
}

.deploymentError {
  background-color: var(--errorColor);
}

.deploymentPending {
  background-color: var(--pendingColor);
}

.deploymentSuccess {
  background-color: var(--successColor);
}

.deploymentContainer {
  width: 100%;
  line-height: 22px;
  display: flex;
  align-items: center;
}

.spacedTable td {
  height: 30px;
}

.marginTopTable {
  margin-top: var(--standardMargin);
}

.sectionSpacer {
  width: 100%;
  height: 6px;
}

.saveColumn {
  text-align: right;
  padding-top: var(--standardMargin) !important;
  padding-right: 0 !important;
}

.saveColumn button {
  margin-left: 15px;
}

.createConfigButton {
  margin-top: var(--standardMargin) !important;
}

.quadLabelColumn {
  width: 20%;
}

.quadValueColumn {
  width: 30%;
}

.errorText {
  color: var(--errorColor);
}

.wrappingCell {
  overflow: visible !important;
  white-space: normal !important;
}

.validationError {
  color: var(--errorColor) !important;
}

.buttonFooter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: var(--largeMargin);
}

.deploymentStatus {
  margin-left: var(--standardMargin);
}
