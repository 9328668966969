.row {
  border: var(--tableBorderStyle);
  border-bottom: 1px solid transparent;
  display: flex;
  padding-left: 0;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  user-select: none;
  cursor: pointer;

  &:hover {
    border-color: var(--surface3Color);
  }
}

.headerRow {
  align-items: center;
  box-sizing: border-box;
  display: flex;
}

.headerCell {
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: var(--labelFontSize);
  color: var(--labelColor);
  font-weight: var(--labelWeight);
  margin-right: var(--smallMargin);
}

.headerCell:last-child {
  margin-right: var(--largeMargin);
}

.row:hover + .row {
  border-top-color: transparent;
}

.row:first-child {
  border-radius: var(--tableBorderRadius) var(--tableBorderRadius) 0px 0px;
}

.row:last-child {
  border-radius: 0px 0px var(--tableBorderRadius) var(--tableBorderRadius);
}

.selectedRow:only-child,
.row:only-child {
  border-radius: var(--tableBorderRadius);
}

.row:nth-child(odd) {
  background: var(--tableOddRowColor);
}

.row:nth-child(even) {
  background: var(--tableEvenRowColor);
}

.row:last-child {
  border-bottom: var(--tableBorderStyle);
}

.row.embedded {
  border-radius: 0;
  border-right: 0;
  border-left: 0;
}

.expansionArea {
  height: var(--dataTableRowHeightStandard);
  display: flex;
  align-items: center;
  justify-content: center;
}

.expansionItem {
  transition: transform var(--defaultSpeed);
  min-width: 7px;
  margin-right: var(--largeMargin);
}

.expansionItem.isOpen {
  transform: rotateZ(90deg);
}

.rowItem {
  line-height: initial;
}

.rowItem svg {
}

.rowItem svg[data-icon='folder'],
.rowItem svg[data-icon='folder-open'] {
  min-width: 16px;
}

.searchContainer {
  padding-bottom: var(--smallMargin);
  position: sticky;
  top: 0;
  z-index: 2;
}

.searchContainer:before {
  content: '';
  background: var(--surface6Color);
  position: absolute;
  width: 100%;
  height: calc(68px);
  top: -30px;
}

.row.highlighted {
  background: var(--accent1Color);
  color: var(--whiteColor);
  border-color: var(--accent1Color);
}

.row.highlighted + .row {
  border-top-color: transparent;
}

.treeContainer {
  font-size: var(--tableFontSize);
}

.treeContainer.compact {
  font-size: 13px;
}

.row:last-child:hover {
  border-bottom-color: var(--surface3Color);
}

.row.compact {
  padding: 0;
}

.linkColumn {
  color: var(--accent3Color);
  font-weight: 600;
}
.linkColumn div > svg {
  color: var(--accent3Color);
}

.row:hover {
  .linkColumn {
    color: var(--blackColor);

    svg {
      color: var(--blackColor);
    }
  }
}

.selectedRow:first-child,
.row:first-child {
  border-radius: var(--tableBorderRadius) var(--tableBorderRadius) 0px 0px;
}

.selectedRow:last-child,
.row:last-child {
  border-radius: 0px 0px var(--tableBorderRadius) var(--tableBorderRadius);
}

.selectedRow:only-child,
.row:only-child {
  border-radius: var(--tableBorderRadius);
}

.rowItem {
  margin-right: var(--smallMargin);
}

.rowItem:last-child {
  margin-right: var(--largeMargin);
}
