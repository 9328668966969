:root {
  --rowHeight: 30px;
  --indent: 15px;
  --borderWidth: 1px;
  --topPadding: 6px;
  --bottomPadding: 6px;
  --checkboxVerticalOffset: 9px;
  --rowTextColor: var(--surface1Color);
  --selectedColor: var(--accent1Color);
  --selectedBorderColor: rgba(255, 255, 255, 0.7);
  --selectedTextColor: var(--whiteColor);
  --textHoverColor: var(--accent2Color);
  --borderColor: #ccc;
  --fontSize: 13px;
}

.row {
  width: 100%;
  cursor: pointer;
  user-select: none;
  display: flex;
  color: var(--rowTextColor);
  padding: 0;
  box-sizing: border-box;
  line-height: normal;
}

.groupHeader {
  padding: var(--standardMargin);
  border-bottom: var(--tableBorderStyle);
  font-weight: 500;
  font-size: 0.95em;
}

.row.searchResult {
  border-bottom: var(--tableBorderStyle);
}

.row.searchResult {
  background: var(--tableOddRowColor);
}

.row.searchResult.evenRow {
  background: var(--tableEvenRowColor);
}

.row.searchResult.selected {
  background: var(--selectedColor);
}

.text {
  padding-bottom: var(--bottomPadding);
  flex: 1;
  height: auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.equipmentName {
  margin-top: calc(var(--insetMargin) + 1px);
  display: block;
  color: var(--surface3Color);
  font-size: 0.93em;
}

.row:hover {
  color: var(--textHoverColor);
  background-color: rgba(0, 0, 0, 0.01);
}

.row:hover .equipmentName {
  color: var(--textHoverColor);
}

.row.searchResult {
  padding-left: var(--standardMargin);
}

.row.selected:hover {
  background-color: var(--selectedColor);
}

.selected .equipmentName {
  color: var(--selectedTextColor) !important;
  opacity: 0.75;
}

.row.selected {
}

.strong {
  font-weight: 600;
}

.text svg {
  margin-left: -8px;
  margin-right: 6px;
  fill: var(--rowTextColor) !important;
  stroke: var(--rowTextColor) !important;
  min-width: 22px;
}

.searchText {
  font-weight: normal;
}

.searchText > input {
  margin-bottom: 0px;
  margin-right: var(--largeMargin);
}

.searchText svg {
  margin-right: var(--standardMargin);
}

.selected .text {
  color: var(--selectedTextColor);
}

.selected {
  color: var(--selectedTextColor);
  background-color: var(--selectedColor);
}

.selected svg {
  fill: var(--selectedTextColor) !important;
  stroke: var(--selectedColor) !important;
}

.selected.arrowContainer svg {
  stroke: var(--selectedTextColor) !important;
}

.text {
  float: left;
  padding-top: var(--topPadding);
  padding-left: 12px;
  display: flex;
}

.sideIconsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  float: right;
  box-sizing: border-box;
}

.arrowContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  float: right;
  padding: 1px;
  box-sizing: border-box;
  visibility: hidden;
}

.arrowContainer.visible {
  visibility: visible;
}

.arrow {
  float: right;
  margin-right: 5px;
}

.treeView {
  margin-top: 0px;
  margin-bottom: 4px;
  overflow-y: auto;
  font-size: var(--fontSize);
}

.locationPicker {
}

.arrowExpanded {
  transform: rotateZ(90deg);
}

.hidden {
  visibility: hidden;
}

.selected .treeDot {
  border: 1px solid var(--whiteColor);
}

.treeDot {
  position: absolute;
  left: -2px;
  background-color: var(--surface6Color);
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid var(--surface3Color);
  width: 8px;
  height: 8px;
  top: calc(50% - 4px);
  margin-right: 2px;
  z-index: 2;
}

.iconElement {
  margin-left: -12px;
  z-index: 3;
  margin-right: -1px;
}

.iconElementCheckbox {
  margin-left: var(--smallMargin);
}

.checkboxDot {
  background: none;
  border: 0;
  border-radius: 0;
  width: 16px;
  height: 10px;
  top: calc(50% - var(--checkboxVerticalOffset));
  margin-left: -8px;
  margin-right: 13px;
}

.selectedBackground {
  background-color: var(--selectedTextColor);
}

.searchField {
  background-color: var(--darkSide1Color) !important;
  color: var(--whiteColor) !important;
  font-size: 1.2em !important;
  padding-left: 15px !important;
  height: 40px !important;
}

.searchFieldContainer {
  margin-left: var(--borderWidth);
  margin-right: 1px;
  background-color: #2f2f2f !important;
  border: var(--borderWidth) solid #333 !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-radius: 0px !important;
}

.rootLevelDot {
  margin-left: 12px;
}

/* COLUMN CLASSES */

.columnContainer {
  pointer-events: none;
  width: 15px;
  min-height: 20px;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

.dotContainer {
  position: relative;
  pointer-events: none;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: -2px;
}

.treeCheckbox {
  width: 16px;
  height: 18px;
  margin-top: 7px;
  margin-left: calc(-1 * var(--topPadding));
  margin-right: 30px;
  z-index: 1;
  background: white;
}

.column {
  width: 15px;
  box-sizing: border-box;
}

.horizontalColumn {
  height: 0px;
  position: relative;
  box-sizing: border-box;
  top: calc(50% - 1px);
  border-bottom: 1px solid var(--borderColor);
}

.halfVerticalColumn {
  border-right: 1px solid var(--borderColor);
  height: 50%;
}

.midHalfVerticalColumn {
  border-right: 1px solid var(--borderColor);
  height: 50%;
  position: absolute;

  box-sizing: border-box;
  top: 50%;
  width: 100%;
}

.dotContainer > .midHalfVerticalColumn {
  left: 1px;
}

.verticalColumn {
  border-right: 1px solid var(--borderColor);
  height: 100%;
}

.selected .verticalColumn,
.selected .halfVerticalColumn,
.selected .midHalfVerticalColumn,
.selected .horizontalColumn {
  border-color: var(--selectedBorderColor);
}

.showMore {
  padding: var(--standardMargin);
  padding-left: var(--largeMargin);
  display: flex;
  align-items: center;
  justify-content: center;
}

.showMore svg {
  margin-right: var(--standardMargin);
}

.showMore:hover {
  color: var(--accent1Color);
}
